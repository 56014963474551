export const lightPrimary = {
	contrast: "#ffffff",
	dark: "#0059b2",
	light: "#736Be9",
	main: "#007fff",
};

export const lightSecondary = {
	contrast: "#ffffff",
	dark: "#0059b2",
	light: "#f6f7fa",
	//main: "#d5d8db",
	main: "#858689",
};

export const lightError = {
	contrast: "#ffffff",
	dark: "#a53531",
	light: "#ef6f6b",
	main: "#ec4c47",
};

export const lightWarning = {
	contrast: "#ffffff",
	dark: "#975b1e",
	light: "#e09b55",
	main: "#d9822b",
};

export const lightInfo = {
	contrast: "#ffffff",
	dark: "#0b4e8d",
	light: "#3f8cd4",
	main: "#1070CA",
};

export const lightSuccess = {
	contrast: "#ffffff",
	dark: "#1b774d",
	light: "#52bb8b",
	main: "#27AB6E",
};

export const lightBackground = {
	default: "#f2f5f8",
	paper: "#ffffff",
};

export const lightText = {
	primary: "#1a1e23",
	secondary: "#969696", // #8c8f93
};

export const lightDrawerdmai = {
	contrast: "#ffffff",
	dark: "#818594",
	light: "#ffffff",
	main: "#8c8f93",
	heading: "#ffffff",
};

export const lightNeutral = {
	100: "#f3f4f7",
	110: "#ffffff",
	111: "#0d1117",
	//111: "#072245", //darkest blue - darkest menu
	1111: "#e1e3ea", //menu border
	112: "#ffffff", //chat background
	113: "#f2f5f8", //crm background
	200: "#e1e3ea",
	300: "#d5d8e1",
	400: "#b7bdcc",
	500: "#9aa1b7",
	600: "#7c85a2",
	700: "#3f455a",
	800: "#2a2f3c",
	900: "#1e212a",
	3000: "#1c2128",
	3090: "#072245", //color for sidebar
	3100: "fff",
	//3100: "#e8ebee", //color for secondsidebar #1a3b66
	//3200: "#314f76", //"#314f76", //color for secondsidebar light hover
	3200: "#313943", //color for sidebar buttons
	//3200: "#12181f", //color for secondsidebar buttons
	3201: "#efefef", //color for secondsidebar active and hover
	3300: "#000000", //secondary sidebar text normal
	3301: "#f2f5f8",
	3400: "#f2f5f8", //tooltip hover backgroundcolor
	3410: "#000000", //tooltip hover textcolor
	3500: "#657587", //navbarsearchbox background
	3501: "#ffffff", //navbarsearchbox background
	3502: "#000000", //navbarsearchbox text
	3505: "#a6afb9", //navbarsearchbox icon
	3600: "#d2e2f1", //background paper special
	4000: "#ffffff", //mainmenu icon color
	5000: "#8c8f93", //#aab9c8 Secondary sidebarMenu heading grey text
	5001: "#8c8f93", //Secondary sidebarMenu active and hover text
	6100: "#f4f7f7", //dividers and borders
	7000: "#f0f0f0", //hover background
	8000: "#8c8f93", //chip neutral
	9000: "#f2f5f8", //main background
	9007: "#ffffff", //paper background
	9008: "#ffffff", //paper background
	9009: "#f2f5f8", //custommention background
	9018: "#8c8f93", //Join Teams button
	//9030: "#e1e3ea", //chat background
	9030: "#ffffff", //chat background
	9031: "#767987", //chat border
	9032: "#e8ebfa", //chat comment is me
	9060: "#ffffff", //iconbutton
	9061: "#ffffff", //iconbutton hover
	9062: "#9c9fa2", //iconbuttontext
	9063: "#000000", //iconbutton hover
	9100: "#03060b", //icon not black
	10000: "#ffffff", //ppt icons & text
	10001: "#bfbfbf", //ppt darkest 
};

export const darkPrimary = {
	contrast: "#ffffff",
	dark: "#0059b2",
	light: "#9599ff",
	main: "#007fff",
};

export const darkSecondary = {
	contrast: "#ffffff",
	dark: "#0059b2",
	light: "#313943", //hovers etc.
	//main: "#007fff",
	main: "#d5d8db",
};

export const darkError = {
	contrast: "#ffffff",
	dark: "#85372b",
	light: "#eb6d57",
	main: "#e6492d",
};

export const darkWarning = {
	contrast: "#ffffff",
	dark: "#8D682d",
	light: "#f7bb57",
	//main: "#f6ab2e",
	main: "#d9822b",
};

export const darkInfo = {
	contrast: "#ffffff",
	dark: "#1D4580",
	light: "#4483df",
	main: "#1664d8",
};

export const darkSuccess = {
	contrast: "#ffffff",
	dark: "#2F6b39",
	light: "#5fc16d",
	main: "#07895d",
};

export const darkBackground = {
	//default: "#2a313a",
	//paper: "#1c2128",
	//default: "#171a1f",
	//paper: "#292c33",
	default: "#010409", //github tritantopia
	paper: "#161b22", //github tritantopia
};

export const darkText = {
	primary: "#d8dade",
	secondary: "#868998",
};

export const darkDrawerdmai = {
	contrast: "#ffffff",
	dark: "#595b65",
	light: "#ffffff",
	main: "#868998",
	heading: "#ffffff",
};

export const darkNeutral = {
	100: "#1e212a",
	110: "#171a1f",
	//111: "#171a1f", //darkest menu old
	//111: "#000",
	//111: "#010409", //darkest menu
	111: "#0d1117", //darkest menu
	1111: "#171a1f", //menu border
	112: "#1e212a",
	//113: "#2a313a", //crm background
	113: "#161b22", //crm background
	//200: "#171a1f", //old
	200: "#232931", //border (dark same as paper)
	300: "#40444f",
	400: "#545863",
	500: "#6c6f7f",
	600: "#868998",
	700: "#a1a4af",
	800: "#bdbfc7",
	900: "#d8dade",
	3000: "#1c2128",
	3090: "#1c2128", //color for sidebar
	3100: "#161b22", //color for secondsidebar
	3101: "#161b22", //color for tablehover
	3200: "#313943", //color for sidebar buttons
	3201: "#313943", //color for secondsidebar active and hover
	3300: "#d1d8e0", //secondary sidebar text normal
	3301: "#313943", //color for secondsidebar light hover
	//3400: "#2a313a", //tooltip hover
	3400: "#0a0a0a",
	3410: "#DCDDDE", //tooltip hover textcolor
	3500: "#1c2128", //navbarsearchbox background
	3501: "#1c2128", //new navbarsearchbox background
	3502: "#797d8b", //new navbarsearchbox text
	3505: "#797d8b", //navbarsearchbox icon
	3600: "#15191F", //background paper special
	4000: "#ffffff", //mainmenu icon color
	5000: "#b9bbbe", //Secondary sidebarMenu heading grey text
	5001: "#b9bbbe", //Secondary sidebarMenu active and hover text
	6100: "#171a1f", //dividers and borders
	7000: "#30353b", //hover background
	8000: "#797d8b", //chip neutral
	9000: "#2a313a", //main background
	9007: "#1c2128", //paper background
	9008: "#1c2128", //paper background
	9009: "#2a313a", //custommentioneditor background
	9018: "#232931", //Join Teams button
	9030: "#232931", //chat background
	9031: "#767987", //chat border
	9032: "#232931", //chat comment is me
	9033: "#232931", //chat comment is other
	9060: "#161b22", //iconbutton
	9061: "#262931", //iconbutton hover
	9062: "#767987", //iconbuttontext
	9063: "#ffffff", //iconbutton hover
	9100: "#ffffff", //icon not black
	10000: "#3c3c3b", //ppt icons & text
	10001: "#575757", //ppt darkest 
};

//stealth
export const stealthPrimary = {
	contrast: "#ffffff",
	dark: "#0059b2",
	light: "#9599ff",
	main: "#007fff",
};

export const stealthSecondary = {
	contrast: "#ffffff",
	dark: "#0059b2",
	light: "#313943", //hovers etc.
	//main: "#007fff",
	main: "#d5d8db",
};

export const stealthError = {
	contrast: "#ffffff",
	dark: "#85372b",
	light: "#eb6d57",
	main: "#e6492d",
};

export const stealthWarning = {
	contrast: "#ffffff",
	dark: "#8D682d",
	light: "#f7bb57",
	//main: "#f6ab2e",
	main: "#d9822b",
};

export const stealthInfo = {
	contrast: "#ffffff",
	dark: "#1D4580",
	light: "#4483df",
	main: "#1664d8",
};

export const stealthSuccess = {
	contrast: "#ffffff",
	dark: "#2F6b39",
	light: "#5fc16d",
	main: "#07895d",
};

export const stealthBackground = {
	default: "#0d1117",
	paper: "#161b22",
};

export const stealthText = {
	primary: "#d8dade",
	secondary: "#8B949E",
	headings: "#5b5d5f",
};

export const stealthDrawerdmai = {
	contrast: "#ffffff",
	dark: "#595b65",
	light: "#ffffff",
	main: "#868998",
	heading: "#ffffff",
};

export const stealthNeutral = {
	100: "#1e212a",
	110: "#171a1f",
	111: "#0d1117", //darkest menu
	1111: "#161b22", //menu border
	112: "#1e212a",
	113: "#121117", //crm background
	200: "#232931", //border (stealth same as paper)
	300: "#40444f",
	400: "#545863",
	500: "#6c6f7f",
	600: "#868998",
	700: "#a1a4af",
	800: "#bdbfc7",
	900: "#d8dade",
	3000: "#1c2128",
	3090: "#1c2128", //color for sidebar
	3100: "#232931", //color for secondsidebar
	3101: "#161b22", //color for tablehover
	3200: "#313943", //color for sidebar buttons
	3201: "#313943", //color for secondsidebar active and hover
	3300: "#d1d8e0", //secondary sidebar text normal
	3301: "#313943", //color for secondsidebar light hover
	3400: "#0d1117", //tooltip hover
	3410: "#DCDDDE", //tooltip hover textcolor
	3500: "#1c2128", //navbarsearchbox background
	3501: "#1c2128", //new navbarsearchbox background
	3502: "#797d8b", //new navbarsearchbox text
	3505: "#797d8b", //navbarsearchbox icon
	3600: "#15191F", //background paper special
	4000: "#ffffff", //mainmenu icon color
	5000: "#b9bbbe", //Secondary sidebarMenu heading grey text
	5001: "#b9bbbe", //Secondary sidebarMenu active and hover text
	6100: "#171a1f", //dividers and borders
	7000: "#30353b", //hover background
	8000: "#797d8b", //chip neutral
	9000: "#0d1117", //main background
	9007: "#0d1117", //paper background main
	9008: "#161b22", //title background (paper)
	9009: "#161b22", //custommentioneditor background
	9018: "#232931", //Join Teams button
	9030: "#232931", //chat background
	9031: "#767987", //chat border
	9032: "#232931", //chat comment is me
	9033: "#232931", //chat comment is other
	9060: "#161b22", //iconbutton
	9061: "#2e343e", //iconbutton hover
	9062: "#767987", //iconbuttontext
	9063: "#ffffff", //iconbutton hover
	9100: "#ffffff",
	10000: "#3c3c3b", //ppt icons & text
	10001: "#575757", //ppt darkest 
};
