import { useMediaQuery } from "@mui/material";
import React, {
	createContext,
	useContext,
	useState,
	useCallback,
	ReactNode,
} from "react";

export interface ILayoutContext {
	isLeftDrawerOpen: boolean;
	isLeftDrawerLocked: boolean;
	isRightDrawerOpen: boolean;
	isRightDrawerWide: boolean;
	rightDrawerContent: ReactNode; // Add this line
	showInRightDrawer: (content: ReactNode) => void; // And this line
	toggleLeftDrawer: () => void;
	toggleRightDrawer: () => void;
	toggleRightDrawerWide: () => void;
	toggleLeftDrawerLocked: () => void;
	isMobile: boolean;
}

/*
const contextValue = useMemo(() => ({
    isLeftDrawerOpen,
    isLeftDrawerLocked,
    // ... other values
}), [isLeftDrawerOpen, isLeftDrawerLocked
	*/

const LayoutContext = createContext<ILayoutContext | undefined>(undefined);

export const useLayout = () => {
	const context = useContext(LayoutContext);
	if (!context) {
		throw new Error("useDrawer must be used within a DrawerProvider");
	}
	return context;
};

export const LayoutProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [isLeftDrawerOpen, setLeftDrawerOpen] = useState(false);
	const [isLeftDrawerLocked, setLeftDrawerLocked] = useState(false); // New state to manage if the drawer is locked
	const [isRightDrawerOpen, setRightDrawerOpen] = useState(false);
	const [isRightDrawerWide, setRightDrawerWide] = useState(false); // State to manage wide drawer
	const [rightDrawerContent, setRightDrawerContent] =
		useState<ReactNode>(null);
	const isMobile = useMediaQuery("(max-width:600px)");

	const showInRightDrawer = (content: ReactNode) => {
		setRightDrawerContent(content);
	};

	const toggleLeftDrawer = useCallback(() => {
		setLeftDrawerOpen((prevState) => !prevState);
	}, []);

	const toggleLeftDrawerLocked = useCallback(() => {
		setLeftDrawerLocked((prevState) => !prevState);
	}, []);

	const toggleRightDrawer = useCallback(() => {
		setRightDrawerOpen((prevState) => !prevState);
	}, []);

	const toggleRightDrawerWide = useCallback(() => {
		setRightDrawerWide((prevState) => !prevState);
	}, []);

	//console.log("DrawerProvider", isLeftDrawerOpen, isLeftDrawerLocked);

	return (
		<LayoutContext.Provider
			value={{
				isLeftDrawerOpen,
				isLeftDrawerLocked,
				isRightDrawerOpen,
				isRightDrawerWide,
				rightDrawerContent,
				showInRightDrawer,
				toggleLeftDrawer,
				toggleLeftDrawerLocked,
				toggleRightDrawer,
				toggleRightDrawerWide,
				isMobile,
			}}
		>
			{children}
		</LayoutContext.Provider>
	);
};
