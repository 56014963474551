import { useEffect, useState } from "react";
import { ProductFeatures } from "./product-features";
import { styled } from "@mui/material/styles";
import {
	Alert,
	AlertTitle,
	AppBar,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Fade,
	Grid,
	LinearProgress,
	Stack,
	Toolbar,
	Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Scrollbar } from "../layout/scrollbar";
import "@/styles/plain.css";
import { CSSTransition } from "react-transition-group";
import { useAuth } from "@/contexts/auth-context";
import React from "react";
import { useLayout } from "@/contexts/LayoutContext";
import { useOfficeContext } from "@/contexts/office-client-context";

const ColorButton = styled(Button)({
	//color: "#000000",
	backgroundColor: "#ffffff",
	"&:hover": {
		backgroundColor: "#ffffff",
	},
	borderRadius: "2.1px",
});

export const SignInPage: React.FC<{ handleSignIn: () => void }> = ({
	handleSignIn,
}) => {
	const [showMessage, setShowMessage] = useState(true);
	const { errorStatus, inProgress } = useAuth();
	const officeContext = useOfficeContext();

	const isMobile = useMediaQuery("(max-width:700px)");

	console.log("Login status", errorStatus, inProgress);

	// Auto login if in addin mode
	useEffect(() => {
		if (officeContext.isAddin) {
			handleSignIn();
		}
	}, [officeContext.isAddin]);

	return (
		<>
			{officeContext.isAddin ? (
				<Fade in={true} timeout={900}>
					<Stack
						display="flex"
						alignItems="center"
						justifyContent="center"
						//onMouseOver={() => setShowMessage(true)}
						// onMouseLeave={() => setShowMessage(false)}
					>
						<Box display="flex" sx={{ mb: 3 }}>
							<Typography
								sx={{
									mb: 0,
									mt: 1,
									fontSize: isMobile ? "25px" : "96px",
									fontWeight: 500,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								DealMaker AI
							</Typography>
						</Box>

						<Typography color="#006bc4" variant="body2">
							Signing in with work account
						</Typography>

						<LinearProgress sx={{ width: "100%", mt: 2 }} />
					</Stack>
				</Fade>
			) : (
				<Fade in={showMessage} timeout={900}>
					<AppBar elevation={0} sx={{ ml: 0, pl: 0 }}>
						<Box
							onMouseOver={() => setShowMessage(true)}
							//onMouseLeave={() => setShowMessage(false)}
							style={{
								backgroundImage: "url('/static/back6.png')",
								backgroundSize: "cover",
								height: "100vh",
								color: "#ffffff",
								overflowY: "scroll",
							}}
						>
							<Container>
								<Toolbar
									disableGutters
									sx={{
										alignItems: "center",
										display: "flex",
										minHeight: 94,
										//pl: 18,
										py: 0,
										pb: 0,
										pr: 0,
									}}
								>
									<Box
										sx={{
											maxHeight: 90,
											width: 950,
											pt: 3,
											mb: 6,

											//border: '1px dashed grey'
										}}
									>
										<Grid container spacing={1}>
											<Grid item xs={5} sm={6}>
												<Stack
													direction="row"
													spacing={0}
												>
													<Box
														sx={{
															pl: 0,
															pr: 1,
															py: 0.6,
															pb: 0.6,
														}}
													>
														<img
															src="/static/icon-192x192.png"
															style={{
																height: isMobile
																	? "34px"
																	: "36px",
																//width: "100%",
															}}
															onClick={() =>
																setShowMessage(
																	true
																)
															}
														/>
													</Box>
												</Stack>
											</Grid>
											<Grid item xs={7} sm={6}>
												<Box
													sx={{
														display: "flex",
														pt: 1,
														justifyContent:
															"flex-end",
														alignItems: "center",
														//border: '1px dashed grey'
													}}
												>
													<ColorButton
														variant="outlined"
														size="medium"
														onClick={handleSignIn}
													>
														<img
															src="/static/msso.png"
															style={{
																marginRight:
																	"0",
																maxWidth: "40",
																maxHeight: "40",
															}}
														/>
														<Typography
															color="#006bc4"
															sx={{
																ml: 1,
																fontSize:
																	isMobile
																		? "10px"
																		: "12px",
																fontWeight: 600,
															}}
														>
															Sign in with
															Microsoft
														</Typography>
													</ColorButton>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Toolbar>
							</Container>
							<div>
								{errorStatus && (
									<Backdrop
										sx={{
											color: "#fff",
											zIndex: (theme) =>
												theme.zIndex.drawer + 1,
										}}
										open={inProgress}
									>
										<Alert severity="error">
											<AlertTitle>Error</AlertTitle>
											{errorStatus} - No subscription
											found for this user
											<Box
												display="flex"
												alignItems="center"
												justifyContent="center"
												sx={{
													mt: "30px",
													width: "100%",
													height: "90px",
												}} // Make sure to set a height if the parent doesn't have one
											>
												<CircularProgress color="inherit" />
											</Box>
										</Alert>
										A problem occurred while trying to sign
										in
									</Backdrop>
								)}

								{inProgress && (
									<Backdrop
										sx={{
											color: "#fff",
											zIndex: (theme) =>
												theme.zIndex.drawer + 1,
										}}
										open={inProgress}
									>
										<Alert variant="filled" severity="info">
											<AlertTitle>
												Verifying user
											</AlertTitle>
											Please wait while we validate your
											Microsoft Account account
											<Box
												display="flex"
												alignItems="center"
												justifyContent="center"
												sx={{
													mt: "10px",
													width: "100%",
													height: "90px",
												}} // Make sure to set a height if the parent doesn't have one
											>
												<CircularProgress color="inherit" />
											</Box>
										</Alert>
									</Backdrop>
								)}

								<Container>
									<CSSTransition
										in={showMessage}
										timeout={300}
										classNames="alert"
										unmountOnExit
									>
										<div>
											<ProductFeatures />
										</div>
									</CSSTransition>
								</Container>
							</div>
						</Box>
					</AppBar>
				</Fade>
			)}
		</>
	);
};
